import { usersPasswords } from "@/api";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useInertiaForm } from "use-inertia-form";

export default function ForgotPassword() {
  const { post, data, getError, setData } = useInertiaForm({
    user: {
      email: "",
    },
  });

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    post(usersPasswords.create.path());
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Forgot your password?</CardTitle>
        <CardDescription>
          Enter your email below to receive reset password instructions
        </CardDescription>
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <form onSubmit={submit} className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="example@gmail.com"
              required
              autoFocus
              autoComplete="email"
              value={data.user.email}
              errors={getError("user.email")}
              onChange={(e) => setData("user.email", e.target.value)}
            />
          </div>

          <Button type="submit" className="w-full">
            Send me reset password instructions
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
