// JsFromRoutes CacheKey 269fa505c0dd28e715b8fe79e48f1f5e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/inertia";

export default {
  new: /* #__PURE__ */ definePathHelper("get", "/users/password/new"),
  edit: /* #__PURE__ */ definePathHelper("get", "/users/password/edit"),
  update: /* #__PURE__ */ definePathHelper("patch", "/users/password"),
  create: /* #__PURE__ */ definePathHelper("post", "/users/password"),
};
