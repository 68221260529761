// JsFromRoutes CacheKey eb507494bbede65cba143a0d3ecd31e1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/inertia";

export default {
  cancel: /* #__PURE__ */ definePathHelper("get", "/users/cancel"),
  new: /* #__PURE__ */ definePathHelper("get", "/users/sign_up"),
  edit: /* #__PURE__ */ definePathHelper("get", "/users/edit"),
  update: /* #__PURE__ */ definePathHelper("patch", "/users"),
  destroy: /* #__PURE__ */ definePathHelper("delete", "/users"),
  create: /* #__PURE__ */ definePathHelper("post", "/users"),
};
