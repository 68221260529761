import { useInertiaForm } from "use-inertia-form";

import { usersRegistrations, usersSessions } from "@/api";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { InertiaLink } from "@/components/ui/inertia-link";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { OAuthButtons } from "@/components/ui/oauth-buttons";

export default function SignUp({ minimumPasswordLength }: { minimumPasswordLength: number }) {
  const { data, setData, post, getError } = useInertiaForm({
    user: {
      name: "",
      email: "",
      password: "",
    },
  });

  const errors = {
    name: getError("user.name"),
    email: getError("user.email"),
    password: getError("user.password"),
  };

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    post(usersRegistrations.create.path());
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Sign up</CardTitle>
        <CardDescription>Enter your email and password below to sign up</CardDescription>
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <form onSubmit={submit} className="grid gap-4">
          <div className="grid grow gap-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              type="text"
              placeholder="John Doe"
              autoComplete="name"
              value={data.user.name}
              errors={errors.name}
              onChange={(e) => setData("user.name", e.target.value)}
            />
          </div>

          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="example@gmail.com"
              required
              autoFocus
              autoComplete="email"
              value={data.user.email}
              errors={errors.email}
              onChange={(e) => setData("user.email", e.target.value)}
            />
          </div>

          <div className="grid gap-2">
            <Label htmlFor="password">
              Password{" "}
              <em className="text-muted-foreground">
                (minimum {minimumPasswordLength} characters)
              </em>
            </Label>
            <Input
              id="password"
              type="password"
              required
              autoComplete="new-password"
              minLength={minimumPasswordLength}
              value={data.user.password}
              errors={errors.password}
              onChange={(e) => setData("user.password", e.target.value)}
            />
          </div>

          <Button type="submit" className="w-full">
            Sign up
          </Button>
        </form>

        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t"></div>
          </div>
          <div className="relative flex justify-center text-sm font-medium leading-6">
            <span className="bg-background px-2">Or continue with</span>
          </div>
        </div>

        <OAuthButtons />

        <div className="text-center text-sm">
          Already have an account?
          <InertiaLink href={usersSessions.new.path()} buttonProps={{ variant: "link" }}>
            Log in
          </InertiaLink>
        </div>
      </CardContent>
    </Card>
  );
}
