import { FormHTMLAttributes } from "react";
import { FormAuthenticityField } from "../form-authenticity-field";
import { Button, ButtonProps } from "./button";

export interface ButtonToProps extends ButtonProps {
  /** The form action URL */
  action: FormHTMLAttributes<HTMLFormElement>["action"];
  /** The form method */
  method: FormHTMLAttributes<HTMLFormElement>["method"];
}

export const ButtonTo = ({ action, method, ...props }: ButtonToProps) => {
  return (
    <form action={action} method={method}>
      <FormAuthenticityField />

      <Button {...props} />
    </form>
  );
};
