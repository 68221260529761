import { useInertiaForm } from "use-inertia-form";

import { usersPasswords, usersRegistrations, usersSessions } from "@/api";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { InertiaLink } from "@/components/ui/inertia-link";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { OAuthButtons } from "@/components/ui/oauth-buttons";
import { Link } from "@inertiajs/react";

export default function Login() {
  const { data, setData, post, getError } = useInertiaForm({
    user: {
      email: "",
      password: "",
      remember: false,
    },
  });

  const errors = {
    email: getError("user.email"),
    password: getError("user.password"),
  };

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    post(usersSessions.create.path());
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>Enter your email below to login to your account</CardDescription>
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <form onSubmit={submit} className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="example@gmail.com"
              required
              autoFocus
              autoComplete="email"
              value={data.user.email}
              errors={errors.email}
              onChange={(e) => setData("user.email", e.target.value)}
            />
          </div>
          <div className="grid gap-2">
            <div className="flex items-center">
              <Label htmlFor="password">Password</Label>
              <Link
                href={usersPasswords.new.path()}
                className="ml-auto inline-block text-sm underline"
              >
                Forgot your password?
              </Link>
            </div>
            <Input
              id="password"
              type="password"
              required
              autoComplete="current-password"
              value={data.user.password}
              errors={errors.password}
              onChange={(e) => setData("user.password", e.target.value)}
            />
          </div>

          <Button type="submit" className="w-full">
            Login
          </Button>
        </form>

        <div className="relative">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t"></div>
          </div>
          <div className="relative flex justify-center text-sm font-medium leading-6">
            <span className="bg-background px-2">Or continue with</span>
          </div>
        </div>

        <OAuthButtons />

        <div className="text-center text-sm">
          Don&apos;t have an account?
          <InertiaLink href={usersRegistrations.new.path()} buttonProps={{ variant: "link" }}>
            Sign up
          </InertiaLink>
        </div>
      </CardContent>
    </Card>
  );
}
