import { usersRegistrations } from "@/api";
import { InertiaLink } from "@/components/ui/inertia-link";

export default function Home() {
  return (
    <div>
      <h1>PaintPal</h1>
      <p>Welcome to PaintPal</p>
      <InertiaLink href={usersRegistrations.edit.path()}>Edit profile</InertiaLink>
    </div>
  );
}
