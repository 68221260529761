import { usersRegistrations, usersSessions } from "@/api";
import { ThemeCustomizer, ThemeProvider, ThemeSwitcher } from "@/components/theme";
import { InertiaLink } from "@/components/ui/inertia-link";
import { TooltipProvider } from "@/components/ui/tooltip";
import { useTypedPage } from "@/hooks/use-typed-page";
import { ReactNode } from "react";

export default function Layout({ children }: { children: ReactNode }) {
  const { flash, isLoggedIn } = useTypedPage().props;

  return (
    <ThemeProvider>
      <TooltipProvider>
        <main className="bg-background">
          <header className="flex justify-between px-2 py-4">
            <div className="flex gap-2">
              {isLoggedIn ? (
                <InertiaLink
                  href={usersSessions.destroy.path()}
                  method="delete"
                  as="button"
                  buttonProps={{ variant: "destructive" }}
                >
                  Logout
                </InertiaLink>
              ) : (
                <>
                  <InertiaLink
                    href={usersRegistrations.new.path()}
                    buttonProps={{ variant: "ghost" }}
                  >
                    Sign up
                  </InertiaLink>

                  <InertiaLink href={usersSessions.new.path()} buttonProps={{ variant: "ghost" }}>
                    Log in
                  </InertiaLink>
                </>
              )}
            </div>
            <div className="flex gap-2">
              <ThemeCustomizer />
              <ThemeSwitcher />
            </div>
          </header>
          <div className="p-4">
            {flash.alert && (
              <div className="mb-4 rounded-md bg-red-500 p-2 text-white">{flash.alert}</div>
            )}
            {flash.notice && (
              <div className="mb-4 rounded-md bg-green-500 p-2 text-white">{flash.notice}</div>
            )}
            {children}
          </div>
          <footer className=""></footer>
        </main>
      </TooltipProvider>
    </ThemeProvider>
  );
}
