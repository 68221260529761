import { usersPasswords } from "@/api";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useInertiaForm } from "use-inertia-form";

export default function EditPassword({
  resetPasswordToken,
  minimumPasswordLength,
}: {
  resetPasswordToken: string;
  minimumPasswordLength: number;
}) {
  const { put, data, getError, setData } = useInertiaForm({
    user: {
      reset_password_token: resetPasswordToken,
      password: "",
      password_confirmation: "",
    },
  });

  const errors = {
    reset_password_token: getError("user.reset_password_token"),
    password: getError("user.password"),
    password_confirmation: getError("user.password_confirmation"),
  };

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    put(usersPasswords.update.path());
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Change your password</CardTitle>
        <CardDescription>
          Enter your email below to receive reset password instructions
        </CardDescription>
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <form onSubmit={submit} className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="password">
              New password{" "}
              <em className="text-muted-foreground">
                (minimum {minimumPasswordLength} characters)
              </em>
            </Label>
            <Input
              id="password"
              type="password"
              autoComplete="new-password"
              minLength={minimumPasswordLength}
              value={data.user.password}
              errors={errors.password}
              onChange={(e) => setData("user.password", e.target.value)}
            />
          </div>

          <div className="grid gap-2">
            <Label htmlFor="password_confirmation">Confirm new password</Label>
            <Input
              id="password_confirmation"
              type="password"
              autoComplete="new-password"
              value={data.user.password_confirmation}
              errors={errors.password_confirmation}
              onChange={(e) => setData("user.password_confirmation", e.target.value)}
            />
          </div>
          <Button type="submit" className="w-full">
            Change my password
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
