import { ButtonTo } from "./button-to";
import { OAuthIcons } from "./oauth-icons";

// TODO: update paths to use helpers
export const OAuthProviders = {
  google_oauth2: {
    path: "/users/auth/google_oauth2",
    icon: <OAuthIcons.Google />,
    text: "Google",
  },
  facebook: {
    path: "/users/auth/facebook",
    icon: <OAuthIcons.Facebook />,
    text: "Facebook",
  },
  twitter: {
    path: "/users/auth/twitter",
    icon: <OAuthIcons.X />,
    text: "X",
  },
} as const;

export function OAuthButtons() {
  return (
    <div className="flex justify-center gap-4">
      {Object.entries(OAuthProviders).map(([provider, { path, icon }]) => (
        <ButtonTo key={provider} method="post" action={path} variant="outline" className="w-full">
          {icon}
        </ButtonTo>
      ))}
    </div>
  );
}
