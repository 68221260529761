import { useInertiaForm } from "use-inertia-form";

import { users, usersRegistrations } from "@/api";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { ButtonTo } from "@/components/ui/button-to";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { OAuthProviders } from "@/components/ui/oauth-buttons";
import { useTypedPage } from "@/hooks/use-typed-page";
import { Identity } from "@/types";
import { router } from "@inertiajs/react";
import * as R from "remeda";

interface EditProps {
  minimumPasswordLength: number;
  isWaitingForConfirmation: boolean;
  shouldShowCurrentPasswordField: boolean;
  unconfirmedEmail: string | null;
}

interface FormProps {
  user: {
    name?: string;
    email?: string;
    password?: string;
    password_confirmation?: string;
    current_password?: string;
  };
}

export default function Edit({
  minimumPasswordLength,
  isWaitingForConfirmation,
  shouldShowCurrentPasswordField,
  unconfirmedEmail,
}: EditProps) {
  const { currentUser } = useTypedPage().props;
  const { data, setData, put, getError } = useInertiaForm<FormProps>({
    user: {
      name: currentUser.name,
      email: currentUser.email,
      password: "",
      password_confirmation: "",
      current_password: "",
    },
  });

  const errors = {
    name: getError("user.name"),
    email: getError("user.email"),
    password: getError("user.password"),
    password_confirmation: getError("user.password_confirmation"),
    current_password: getError("user.current_password"),
  };

  const initials = currentUser.name
    ? currentUser.name.split(" ").map((nameParts) => nameParts[0].toUpperCase())
    : "";

  const [connectedProviders, notConnectedSocialProviders] = R.partition(
    R.entries(OAuthProviders),
    ([provider]) => currentUser.identities.some((identity) => identity.provider === provider),
  );

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    put(usersRegistrations.update.path());
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <div className="flex items-center justify-between">
          <div className="grid">
            <CardTitle className="text-2xl">Edit Profile</CardTitle>
            <CardDescription>
              Update your profile information and manage your linked accounts
            </CardDescription>
          </div>
          <Avatar className="size-16">
            <AvatarImage src={currentUser.image} alt={`${currentUser.name} avatar`} />
            {initials && <AvatarFallback>{initials}</AvatarFallback>}
          </Avatar>
        </div>
      </CardHeader>

      <CardContent className="flex flex-col gap-4">
        <form onSubmit={submit} className="grid gap-4">
          <div className="flex items-center space-x-4">
            <div className="grid grow gap-2">
              <Label htmlFor="name">Name</Label>
              <Input
                id="name"
                type="text"
                placeholder="John Doe"
                autoComplete="name"
                value={data.user.name}
                errors={errors.name}
                onChange={(e) => setData("user.name", e.target.value)}
              />
            </div>
          </div>

          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="example@gmail.com"
              required={currentUser.identities.length === 0}
              autoComplete="email"
              value={data.user.email}
              errors={errors.email}
              onChange={(e) => setData("user.email", e.target.value)}
            />
          </div>

          {isWaitingForConfirmation && (
            <div className="text-sm text-muted-foreground">
              Currently waiting confirmation for: {unconfirmedEmail}
            </div>
          )}

          <div className="grid gap-2">
            <Label htmlFor="password">
              Password{" "}
              <em className="text-muted-foreground">
                (leave blank if you don&apos;t want to change it)
              </em>
            </Label>
            <Input
              id="password"
              type="password"
              autoComplete="new-password"
              minLength={minimumPasswordLength}
              value={data.user.password}
              errors={errors.password}
              onChange={(e) => setData("user.password", e.target.value)}
            />
            {minimumPasswordLength > 0 && (
              <span className="text-sm text-muted-foreground">
                {minimumPasswordLength} characters minimum
              </span>
            )}
          </div>

          <div className="grid gap-2">
            <Label htmlFor="password_confirmation">Password confirmation</Label>
            <Input
              id="password_confirmation"
              type="password"
              autoComplete="new-password"
              minLength={minimumPasswordLength}
              value={data.user.password_confirmation}
              errors={errors.password_confirmation}
              onChange={(e) => setData("user.password_confirmation", e.target.value)}
            />
          </div>

          {shouldShowCurrentPasswordField && (
            <div className="grid gap-2">
              <Label htmlFor="current_password">
                Current password{" "}
                <span className="text-muted-foreground">
                  (we need your current password to confirm your changes)
                </span>
              </Label>
              <Input
                id="current_password"
                type="password"
                required
                autoComplete="current-password"
                value={data.user.current_password}
                errors={errors.current_password}
                onChange={(e) => setData("user.current_password", e.target.value)}
              />
            </div>
          )}

          <Button type="submit" className="w-full">
            Update Profile
          </Button>
        </form>

        <div className="space-y-4 text-center">
          <h3 className="text-lg font-medium">Linked Accounts</h3>
          <div className="grid gap-2">
            {currentUser.identities.length === 0 ? (
              <p className="text-muted-foreground">Social accounts are not linked yet.</p>
            ) : (
              connectedProviders.map(([provider, { text, icon }]) => {
                const identity = currentUser.identities.find(
                  (identity) => identity.provider === provider,
                )!;

                return (
                  <div key={identity.id} className="flex items-center gap-2">
                    {icon} {text}
                    <UnlinkSocialProvider providerName={text} id={identity.id} />
                  </div>
                );
              })
            )}
          </div>
        </div>

        {notConnectedSocialProviders.length > 0 && (
          <div className="space-y-4 text-center">
            <h3 className="text-lg font-medium">Link More Accounts</h3>
            <div className="flex justify-center gap-4">
              {notConnectedSocialProviders.map(([provider, { path, icon }]) => (
                <ButtonTo
                  key={provider}
                  method="post"
                  action={path}
                  variant="outline"
                  className="w-full"
                >
                  {icon}
                </ButtonTo>
              ))}
            </div>
          </div>
        )}

        <CancelAccount />
      </CardContent>
    </Card>
  );
}

function CancelAccount() {
  const handleCancelAccount = () => {
    router.delete(usersRegistrations.destroy.path());
  };

  return (
    <AlertDialog>
      <div className="flex items-center justify-center gap-2 text-center">
        <p className="text-sm">Unhappy?</p>
        <AlertDialogTrigger asChild>
          <Button variant="linkDestructive">Cancel my account</Button>
        </AlertDialogTrigger>
      </div>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently delete your account and remove your
            data from our servers.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleCancelAccount}>
            Yes, cancel my account
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

function UnlinkSocialProvider({ id, providerName }: { id: Identity["id"]; providerName: string }) {
  const handleUnlink = () => {
    router.delete(users.unlinkProvider.path({ id }));
  };

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button variant="outline" size="sm" className="ml-auto">
          Unlink
        </Button>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
          <AlertDialogDescription>
            This action cannot be undone. This will permanently unlink your {providerName} account
            from your profile.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleUnlink}>Yes, unlink my account</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
