// JsFromRoutes CacheKey f7c445aa3b7d69ad384dd5298a56a7aa
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/inertia";

export default {
  new: /* #__PURE__ */ definePathHelper("get", "/users/sign_in"),
  create: /* #__PURE__ */ definePathHelper("post", "/users/sign_in"),
  destroy: /* #__PURE__ */ definePathHelper("delete", "/users/sign_out"),
};
